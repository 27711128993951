import React, { useEffect, useCallback, useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import useForm18nValidation from "../../../utils/use-form-i18n-validation";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CardWeather, { WeatherInfoTemplate, ForecastItemTemplate, weatherThemeSettings } from "./CardWeather";

import FormControl from "@mui/material/FormControl";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";

import { useForm, FormContainer, TextFieldElement, SelectElement, CheckboxElement } from 'react-hook-form-mui';
import { addWidget } from "../../../redux/actions/Content";

export default function DialogBoxWeather({ isOpen, onClose, isIcon }) {

  const { t } = useTranslation();
  const { parseError18n, parseErrors18n } = useForm18nValidation();

  const [items, setItems] = useState([]);

  const [scale, setScale] = useState("");
  const [location, setLocation] = useState("");
  const [language, setLanguage] = useState("");
  const [font, setFont] = useState("");
  const [theme, setTheme] = useState("#808080");
  const [strip, setStrip] = useState("");
  const [name, setName] = useState("");
  const [wind, setWind] = useState("");
  const [previewTheme, setPreviewTheme] = useState("#808080");
  const [temperature, setTemperature] = useState(undefined);
  const [city, setCity] = useState("");
  const [pressure, setPressure] = useState(undefined);
  const [humidity, setHumidity] = useState(undefined);
  const [description, setDescription] = useState("");
  const [rain, setRain] = useState(undefined);
  const [icon, setIcon] = useState(undefined);
  const [error, setError] = useState("");
  const [forecast, setForecast] = useState([]);

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.User);
  const { currentFolder } = useSelector((state) => state.FileFolder);

  const { isLoaded } = useJsApiLoader({
    id: 'weather-dialog-box',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const locationInputRef = useRef();
  const placeObjectRef = useRef(null);

  const DefaultWeatherWidgetSettings = useMemo(() => ({
    scale: 'F',
    language: 'en',
    location: {
      formattedAddress: null,
    },
    useDeviceLocation: false,
    fontSize: 'L',
    theme: '#808080'
  }), [t]);

  const [weatherData, setWeatherData] = useState({});
  const [forecastData, setForecastData] = useState([]);

  const weatherScaleOptions = useMemo(() => ([
    { id: "F", label: t("views.dialogBoxWeather.fahrenheit") },
    { id: "C", label: t("views.dialogBoxWeather.celsius") }
  ]), [t]);

  const weatherLanguageOptions = useMemo(() => ([
    { id: "en", label: t("views.dialogBoxWeather.language.en") },
    { id: "es", label: t("views.dialogBoxWeather.language.es") },
    { id: "de", label: t("views.dialogBoxWeather.language.de") },
    { id: "pl", label: t("views.dialogBoxWeather.language.pl") }
  ]), [t]);

  const weatherFontSizeOptions = useMemo(() => ([
    { id: "S", label: t("views.dialogBoxWeather.sizeS") },
    { id: "L", label: t("views.dialogBoxWeather.sizeL") },
    { id: "XL", label: t("views.dialogBoxWeather.sizeXL") },
    { id: "XXL", label: t("views.dialogBoxWeather.sizeXXL") }
  ]), [t]);

  const weatherThemeOptions = useMemo(() => ([
    { id: "#808080", label: t("views.dialogBoxWeather.black") },
    { id: "#90EE90", label: t("views.dialogBoxWeather.yellow") }
  ]), [t]);

  const [widgetFormErrors, setWidgetFormErrors] = useState({});
  const widgetForm = useForm({
    defaultValues: {
      name: '',
      place: '',
      ...DefaultWeatherWidgetSettings
    },
    errors: widgetFormErrors
  });
  const widgetFormValues = widgetForm.watch();

  const useDeviceLocation = widgetForm.watch('useDeviceLocation');
  useEffect(() => {
    if (useDeviceLocation) {
      widgetForm.setValue('place', '');
      widgetForm.setValue('location', {
        formattedAddress: null,
        useDeviceLocation: true
      });
    }

  }, [useDeviceLocation]);

  const handleLocationChange = useCallback(() => {
    const placeObject = locationInputRef.current.getPlace();

    placeObjectRef.current = placeObject;

    const lat = placeObject.geometry.location.lat();
    const lng = placeObject.geometry.location.lng();
    const formattedAddress = placeObject.formatted_address;

    widgetForm.setValue('location', {
      lat, lng, formattedAddress
    });
    widgetForm.setValue('place', formattedAddress);
  }, [widgetForm]);

  const handleClose = useCallback(() => {
    onClose?.(false);
    widgetForm.reset();
  }, [onClose, widgetForm]);

  const handleError = useCallback((data) => {
    const errors = parseErrors18n(data);

    setWidgetFormErrors(errors);
  }, [parseErrors18n]);

  const handleSubmit = useCallback((data) => {
    setWidgetFormErrors({});
    onClose?.(false);

    const { name, place, useDeviceLocation, ...weather } = data;

    dispatch(
      addWidget({
        name,
        type: "widget",
        widgetCat: "weather",
        widgetIcon: isIcon,
        url: "",
        html: "",
        weather: {
          ...weather,
          template: WeatherInfoTemplate,
          forecastItemTemplate: ForecastItemTemplate,
          themeSettings: weatherThemeSettings(weather.theme)
        },
        folderId: currentFolder,
        user: user._id,
      })
    );
  }, [dispatch, isIcon, onClose]);

  const handleLocationBlur = useCallback((e) => {
    const placeObject = placeObjectRef.current
    const formattedAddress = placeObject?.formatted_address;

    widgetForm.setValue('place', formattedAddress);
  }, [widgetForm]);

  useEffect(() => {
    placeObjectRef.current = null;
    widgetForm.reset();
  }, [isOpen])

  if (!isLoaded) {
    return (
      <>
        <h1>{t("views.dialogBoxWeather.loading")}</h1>
      </>
    );
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
          }}>
          <img src="/images/weather-widget.png" />
        </div>
        <DialogTitle sx={{ textAlign: "center" }}>{t("views.dialogBoxWeather.weather")}</DialogTitle>
        <FormContainer formContext={widgetForm} onError={handleError} onSuccess={handleSubmit}>
          <DialogContent>
            <TextFieldElement
              autoFocus
              margin="dense"
              label={t("views.dialogBoxWeather.name")}
              type="text"
              fullWidth
              size="small"
              variant={"standard"}
              name="name"
              required
              parseError={parseError18n}
            />
            <br />
            <br />
            <FormControl fullWidth>
              <SelectElement required name="scale" label={t("views.dialogBoxWeather.scale")} options={weatherScaleOptions} />
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <Autocomplete onLoad={(autocomplete) => (locationInputRef.current = autocomplete)} onPlaceChanged={handleLocationChange}>
                <TextFieldElement
                  fullWidth required name="place" inputProps={{ onBlur: handleLocationBlur }}
                  label={t("views.dialogBoxWeather.location")} disabled={useDeviceLocation}
                />
              </Autocomplete>
            </FormControl>
            <br />
            <CheckboxElement name="useDeviceLocation" size="small" label={t("views.dialogBoxWeather.useDeviceLocation")} />
            <br />
            <br />
            <FormControl fullWidth>
              <SelectElement required name="language" label={t("views.dialogBoxWeather.selectLanguage")} options={weatherLanguageOptions} />
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <SelectElement required name="fontSize" label={t("views.dialogBoxWeather.selectFontSize")} options={weatherFontSizeOptions} />
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <SelectElement required name="theme" label={t("views.dialogBoxWeather.selectTheme")} options={weatherThemeOptions} />
            </FormControl>
            <h5>{t("views.dialogBoxWeather.preview")}</h5>
            <CardWeather item={{
              weather: widgetFormValues
            }} />
            <br />
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 2,
              marginTop: 1,
            }}>
            <Button variant="outline" color="primary" onClick={handleClose}>
              {t("views.dialogBoxWeather.cancel")}
            </Button>

            <Button variant="contained" color="primary" type="submit">
              {t("views.dialogBoxWeather.save")}
            </Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
    </div>
  );
}
