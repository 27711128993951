import React, { useMemo, useState, useEffect } from "react";
import moment from "moment/moment";

export const WeatherInfoTemplate = `
  <div
    style="
      background-color: {{bgColor}};
      background-image: url({{bgImg}});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 100%;
      width: 100%;
      border-radius: 10px;
      color: white;
      display: flex;
      justify-content: center;
      alignItems: center;
      flex-direction: column;
      font-size: {{fontSize}}px;
    "
  >
    <div
      style="
        display: flex;
        align-items: center;
        flex-direction: column;
      "
    >
      <h1>{{formattedAddress}}</h1>
      <img src="http://openweathermap.org/img/w/{{weatherIcon}}.png" width="100" />
      <h1 style="fontSize:70px;">{{temperature}}°{{scale}}</h1>
      <div
        style="
          display: flex;
          align-items: center;
          gap: 10px;
        "
      >
        <div style=" 
          display: flex;
          align-items: center; 
          gap: 10px;
         "
        >
          <h3>{{humidity}}%</h3>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            gap: 10px;
            margin-left: 50px;
          "
        >
          <h3>{{pressure}} hPa</h3>
        </div>
        </div>
      </div>
      <div
        style="
          background-color: rgba(135,206,250,.5);
          aspect-ratio: 3 / 1;
          width: 100%;
          border-radius: 10px;
          margin-bottom: 60px;
          display: flex;
          align-items: center;
          width: 80%;
          margin: 0 auto 40px auto;
          "
      >
        <div
          style="
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
            width: 100%;
            "
        >
         {{forecast}}
        </div>
      </div>
    </div>
`;

export const ForecastItemTemplate = `
  <div 
    style="
      display: grid;
      flex-direction: column;
      align-items: center;
      text-align: center;
    "
  >
    <p>{{datetime}}</p>
    <img src="http://openweathermap.org/img/w/{{weatherIcon}}.png" style="margin: 0 auto" />
    <p>
      <span>{{maxTemperature}}°{{scale}}</span> - <span>{{minTemperature}}°{{scale}}</span>
    </p>
  </div>
`;

export const weatherThemeSettings = (themeValue) => {

  const images = {
    bg1: `/images/BG_1.jpeg`,
    bg2: `/images/BG_2.jpeg`,
  };

  switch (themeValue) {
    case "#808080":
      return { bgImg: images.bg1, bgColor: "#808080" };
    case "#90EE90":
      return { bgImg: images.bg2, bgColor: "#90EE90" };
    default:
      return null;
  }
};

const API_KEY = "b589b102afcfbd7027b38ea5b816f17a";

export const getWeatherUrl = (lat, lng, lang, units) => `//api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=${API_KEY}&lang=${lang}&units=${units}`;

export const getForecastUrl = (lat, lng, lang, units) => `//api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lng}&appid=${API_KEY}&lang=${lang}&units=${units}`;

const getWeather = async (lat, lng, lang, units) => {
  const currentWeatherURL = getWeatherUrl(lat, lng, lang, units);
  const currentWeatherResponse = await fetch(currentWeatherURL);
  const currentWeatherData = await currentWeatherResponse.json();

  const forecastURL = getForecastUrl(lat, lng, lang, units);
  const forecastResponse = await fetch(forecastURL);
  const forecastData = await forecastResponse.json();

  return {
    current: currentWeatherData,
    forecast: forecastData
  };

}

const CardWeather = ({ item, style }) => {

  const [weatherData, setWeatherData] = useState(null);
  useEffect(() => {
    getWeather(
      item.weather?.location?.lat ?? '40.730610',
      item.weather?.location?.lng ?? '-73.935242',
      item.weather?.language ?? 'en',
      item.weather?.scale == 'F' ? 'imperial' : 'metric'
    ).then(data => setWeatherData(data));
  }, [item?.weather?.location, item?.weather?.language, item?.weather?.scale]);


  const previewHTML = useMemo(() => {
    if (weatherData == null)
      return "";

    const { weather: weatherSettings } = item;
    const { current: currentWeather, forecast: forecastWeather } = weatherData;

    const theme = weatherThemeSettings(weatherSettings.theme);

    let forecastHTML = forecastWeather.list.filter(item => {
      let currentTimeStart = moment().startOf('day');
      let forecastTimeStart = moment(item.dt_txt).startOf('day');

      let forecastHour = moment(item.dt_txt).hour();

      return (!currentTimeStart.isSame(forecastTimeStart) && forecastHour == 15)
    }).map((weather) => {
      return ForecastItemTemplate
        .replace("{{datetime}}", new Date(weather.dt_txt).toLocaleDateString({ day: "numeric", weekday: "short" }))
        .replace("{{weatherIcon}}", weather.weather[0].icon)
        .replace("{{maxTemperature}}", Math.ceil(weather.main.temp_max))
        .replace("{{minTemperature}}", Math.floor(weather.main.temp_min));

    }).join('')

    let fontSizes = {
      S: 12,
      L: 14,
      XL: 16,
      XXL: 18
    };

    let html = WeatherInfoTemplate
      .replace("{{bgColor}}", theme.bgColor)
      .replace("{{bgImg}}", theme.bgImg)
      .replace("{{fontSize}}", fontSizes[weatherSettings?.fontSize ?? 'L'])
      .replace("{{forecast}}", forecastHTML)
      .replace("{{formattedAddress}}", weatherSettings?.location?.formattedAddress ?? currentWeather.name)
      .replace("{{weatherIcon}}", currentWeather.weather[0].icon)
      .replace("{{temperature}}", currentWeather.main.temp)
      .replace("{{humidity}}", currentWeather.main.humidity)
      .replace("{{pressure}}", currentWeather.main.pressure)
      .replaceAll("{{scale}}", weatherSettings.scale)

    return html;
  }, [weatherData, item]);

  return (
    <div style={style} dangerouslySetInnerHTML={{ __html: previewHTML }} />
  );
};

export default CardWeather;