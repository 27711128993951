import { useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  TextField,
  Typography,
  Card,
  CardHeader,
  FormControl,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import RedesignBodyHeader from "../../components/bodyheader/RedesignBodyHeader";
import RedesignBodyHeaderBottom from "../../components/bodyheader/RedesignBodyHeaderBottom";
import Layout from "../../components/layout/Layout";
import { FaList } from "react-icons/fa";
import FolderIcon from "@mui/icons-material/Folder";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  addContent,
  checkedSingleContent,
  contentMoveToFolder,
  deleteContent,
  deleteMultipleContent,
  flagContentOff,
  getContentById,
  addContentFromGoogleDrive,
  addContentFromOneDrive,
  unCheckedSignleContent,
  moveMultipleContent,
} from "../../redux/actions/Content";
import { setFolderId } from "../../redux/actions/FileFolder";
import All from "./components/All";
import Images from "./components/Images";
import Videos from "./components/Videos";
import { addContentInPlaylist, playlistFlagOff } from "../../redux/actions/Playlist";
import AddContentModel from "../../components/addcontent/AddContentModel";
import toast from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import { editFolder, folderDelete, folderFlagOff, getFolders } from "../../redux/actions/FileFolder";
import Folders from "./components/Folders";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ConfirmationModel from "../../components/confirmmodel/ConfirmationModel";
import Circularbar from "../../components/circularbar/Circularbar";
import { checkedContent, unCheckedContent } from "../../redux/actions/Content";
import Previewer from "./components/Previewer";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import useGoogleDrivePicker from "../../hooks/use-googledrive-picker";
import useOneDrivePicker from "../../hooks/use-onedrive-picker";

import CreateWidgetModel from "./components/CreateWidgetModel";
import Widgets from "./components/Widgets";

// new

import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";

//icons

import MoveUpIcon from "@mui/icons-material/MoveUp";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import ImageIcon from "@mui/icons-material/Image";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import { useSettings } from "src/hooks/use-settings";
import { paths } from "src/paths";

const components = {
  all: All,
  images: Images,
  videos: Videos,
  widgets: Widgets,
};
function RedesignFile({ socketRef, user }) {
  const { t } = useTranslation();
  const settings = useSettings();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const handle = useFullScreenHandle();

  const [openGoogelDrivePicker, googleDriveAuth] = useGoogleDrivePicker();
  const [googleDriveFiles, setGoogleDriveFiles] = useState([]);

  const { openOneDrivePicker } = useOneDrivePicker();

  const { folderId } = useParams();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("apps")) {
      setOpenWidget(true);
      navigate(paths.files.index);
    }
  }, [location]);

  useEffect(() => {
    dispatch(setFolderId(folderId || "root"));
  }, [folderId]);

  const { content, isDeleting, isDeleted, isContentAddedInContent, isContentAdding, uploadFailedError, uploadFailedErrorMessage } = useSelector((state) => state.Content);

  const { screens } = useSelector((state) => state.Screen);
  const { playlists, isContentAdded } = useSelector((state) => state.Playlist);
  const { currentFolder, allFolders, userFolders, contentFolders, files, isFolderCreated, isFolderDeleted } = useSelector(
    (state) => ({
      currentFolder: state.FileFolder.currentFolder,
      userFolders: state.FileFolder.userFolders.filter((f) => f.parent === state.FileFolder.currentFolder && f?.folderFor === "content"),
      allFolders: state.FileFolder.userFolders,
      contentFolders: state.FileFolder.userFolders.filter((f) => f?.folderFor === "content"),
      files: state.Content.content.filter((c) => c.folderId === state.FileFolder.currentFolder),
      isFolderCreated: state.FileFolder.isFolderCreated,
      isFolderDeleted: state.FileFolder.isFolderDeleted,
    }),
    shallowEqual
  );

  const [openPlaylist, setOpenPlaylist] = useState(false);
  const [playlistId, setPlaylistId] = useState("");
  const [ids, setIds] = useState("");
  const [folderVal, setFolderVal] = useState("");
  const [contentObj, setContentObj] = useState({});
  const [search, setSearch] = useState("");
  const [browse, setBrowse] = useState(false);
  const [openMoveModel, setOpenMoveModel] = useState(false);
  const [openBulkMoveModel, setOpenBulkMoveModel] = useState(false);
  const [file, setFile] = useState([]);
  const [key, setKey] = useState("all");
  const [openCreateModel, setOpenCreateModel] = useState(false);
  const [openFolderEditModel, setOpenFolderEditModel] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [viewValue, setViewValue] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openBulkDeleteModel, setOpenBulkDeleteModel] = useState(false);
  const [previewContentUrl, setPreviewContentUrl] = useState({ url: "", type: "" });
  const [openPreviewContentModel, setOpenPreviewContentModel] = useState(false);
  const [openWidget, setOpenWidget] = useState(false);
  const [datas, setData] = useState([]);
  const Component = components[key];

  useEffect(() => {
    dispatch(getContentById(user?._id));
    dispatch(getFolders(user?._id));
  }, []);

  useEffect(() => {
    if (isContentAdded) {
      toast.success(t("views.redesignFile.contentAdded"));
      const s = screens.find((c) => c?.playlist?._id === playlistId);
      console.log(s);
      if (s !== undefined || s !== null) {
        emitOnAddContentInPlaylist(s?.mac);
      }
      dispatch(playlistFlagOff());
    }
    if (isFolderCreated) {
      toast.success(t("views.redesignFile.folderCreated"));
      dispatch(folderFlagOff());
    }
    if (isFolderDeleted) {
      toast.success(t("views.redesignFile.folderDeleted"));
      dispatch(folderFlagOff());
    }
    if (isContentAddedInContent) {
      toast.success(t("views.redesignFile.contentAddedSuccessfully"));
      dispatch(flagContentOff());
    }
    if (isDeleted) {
      toast.success(t("views.redesignFile.contentDeletedSuccessfully"));
      dispatch(flagContentOff());
    }
  }, [isContentAdded, isFolderCreated, isFolderDeleted, isContentAddedInContent, isDeleted]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", function (e) {
      if (!document.fullscreenElement) {
        setPreviewContentUrl({ url: "", type: "" });
        setOpenPreviewContentModel(false);
      }
    });
  }, []);

  const emitOnAddContentInPlaylist = (mac) => {
    if (socketRef) {
      socketRef?.current.emit("content_added", { detail: { mac: mac } });
    }
  };

  const onAddPlaylist = (contentObject) => {
    setContentObj({ ...contentObject });
    setOpenPlaylist(true);
  };

  const addInPlaylist = () => {
    // console.log('from file add in playlist=>',contentObj)
    dispatch(addContentInPlaylist({ id: playlistId, value: contentObj.id }));
    setOpenPlaylist(false);
  };

  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(Math.round(video.duration * 1000)); // Convert to milliseconds and round
      };

      video.onerror = function () {
        reject("An error occurred while loading video metadata");
      };

      video.src = URL.createObjectURL(file);
    });
  };

  const fileUpload = async (e) => {
    const file = e.target.files;
    const fileArray = Array.from(file);

    const processedFiles = await Promise.all(
      fileArray.map(async (file) => {
        let duration = "";
        if (file.type.startsWith("video/")) {
          try {
            duration = await getVideoDuration(file);
            console.log(`Video ${file.name}: ${duration} milliseconds`);
          } catch (error) {
            console.error(`Error getting duration for ${file.name}:`, error);
          }
        }

        // Create a new File object with modified name including duration
        return new File([file], `${duration}_${file.name}`, { type: file.type });
      })
    );

    const form = new FormData();

    processedFiles.forEach((file) => {
      form.append("content", file);
    });

    form.append("user", user.isMember ? user?.user._id : user._id);
    form.append("subuser", user.isMember ? user?.user._id : user._id);
    form.append("role", user.isMember ? user?.role.split("-")[0] : user?.role);
    form.append("folderId", currentFolder);

    const newContent = (await dispatch(addContent(form)))?.map(c => c.id);

    if (newContent && newContent.some(id => content.find(c => c.id == id))) {
      toast.success(t("views.redesignFile.similarContentRemoved"));
    }

    setBrowse(false);
  };

  const fileUploadDragDrop = async (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files;
    const fileArray = Array.from(file);

    const processedFiles = await Promise.all(
      fileArray.map(async (file) => {
        let duration = "";
        if (file.type.startsWith("video/")) {
          try {
            duration = await getVideoDuration(file);
            console.log(`Video ${file.name}: ${duration} milliseconds`);
          } catch (error) {
            console.error(`Error getting duration for ${file.name}:`, error);
          }
        }

        // Create a new File object with modified name including duration
        return new File([file], `${duration}_${file.name}`, { type: file.type });
      })
    );

    const includeNames = processedFiles.map((file) => file.name.split("_")[1]);
    const includedItems = content.filter((item) => includeNames.includes(item.name));
    const cutting = processedFiles.filter((file) => !includedItems.map((item) => item.name).includes(file.name.split("_")[1]));

    const form = new FormData();

    if (includedItems.length <= 0) {
      processedFiles.forEach((file) => {
        form.append("content", file);
      });
    } else {
      toast.success(t("views.redesignFile.similarContentRemoved"));
      cutting.forEach((file) => {
        form.append("content", file);
      });
    }

    form.append("user", user.isMember ? user?.user._id : user._id);
    form.append("subuser", user.isMember ? user?.user._id : user._id);
    form.append("role", user.isMember ? user?.role.split("-")[0] : user?.role);
    form.append("folderId", currentFolder);

    dispatch(addContent(form));
    setBrowse(false);
  };

  const onDelete = (id) => {
    setOpenDeleteModel(true);
    setIds(id);
  };

  const onConfirmDeleteFile = () => {
    dispatch(deleteContent(ids));
    setOpenDeleteModel(false);
  };

  const onMove = (id) => {
    setIds(id);
    setOpenMoveModel(true);
  };

  const moveOnFolder = () => {
    setOpenMoveModel(false);
    setFolderVal("");
    dispatch(contentMoveToFolder({ id: ids, folderId: folderVal }));
  };

  const moveOnFolderBulk = async () => {
    setOpenBulkMoveModel(false);
    setFolderVal("");

    const _checkedList = content.filter((c) => c.checked === true);

    let moveErrors = 0;

    for (const item of _checkedList) {
      try {
        await dispatch(contentMoveToFolder({ id: item.id, folderId: folderVal }));
      } catch (error) {
        console.error(`Error moving item ${item.id}:`, error);
        moveErrors++;
      }
    }

    if (moveErrors === 0) {
      toast.success(t("views.redesignFile.allContentMovedSuccessfully"));
    } else if (moveErrors < _checkedList.length) {
      toast.warning(t("views.redesignFile.someContentFailedToMove", { count: moveErrors }));
    } else {
      toast.error(t("views.redesignFile.allContentFailedToMove"));
    }

    setIsChecked(false);
  };

  const onFolderDelete = (id) => {
    const childrenfiles = content.filter((c) => c.folderId === id);
    const childrenfolder = allFolders.filter((f) => f.parent === id);
    // console.log(childrenfolder);
    if (childrenfiles.length == 0 && childrenfolder.length == 0) {
      setIds(id);
      setOpenCreateModel(true);
    } else {
      toast.success(t("views.redesignFile.folderContainsContent"));
    }
  };

  const onConfirm = () => {
    setOpenCreateModel(false);
    dispatch(folderDelete(ids));
  };

  const onFolderEdit = (id, name) => {
    setIds(id);
    setFolderName(name);
    setOpenFolderEditModel(true);
  };

  const onConfirmFolderEdit = () => {
    setOpenFolderEditModel(false);

    const isEmpty = folderName === "";
    const isAvailable = userFolders.find((f) => f.name.toLocaleUpperCase() === folderName.toLocaleUpperCase());

    if (isEmpty) {
      toast.error(t("views.redesignFile.enterFolderName"));
    } else if (isAvailable) {
      toast.success(t("views.redesignFile.folderAlreadyAvailable"));
    } else {
      dispatch(editFolder(ids, folderName));
    }
  };

  const checkIfSelected = () => {
    if (content.some((c) => c.checked === true)) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  const onCheckedContent = () => {
    //dispatch(checkedContent(currentFolder));
    setIsCheckedAll(true);
    checkIfSelected();
  };
  const onUncheckedContent = () => {
    dispatch(unCheckedContent(currentFolder));
    setIsCheckedAll(false);
    checkIfSelected();
  };
  const onCheckedSingleContent = (id) => {
    dispatch(checkedSingleContent(id));
    checkIfSelected();
  };

  const onUncheckedSingleContent = (id) => {
    dispatch(unCheckedSignleContent(id));
    checkIfSelected();
  };

  const onGetCheckedContent = () => {
    setOpenBulkDeleteModel(true);
  };

  const onMoveCheckedContent = () => {
    setOpenBulkMoveModel(true);
  };

  const onBulkDeleteFileConfirm = () => {
    setOpenBulkDeleteModel(false);
    const _checkedList = content.filter((c) => c.checked === true);
    const _selectedContent = [];
    playlists.forEach((playlist) => {
      _selectedContent.push(...playlist.content);
    });
    const __gettingWithoutNull = _selectedContent.filter((_content) => _content.content !== null);
    const __gettingIdFromCheckedContent = _checkedList.map((_content) => _content.id);
    const __gettingUsingContent = __gettingWithoutNull.filter((item) => __gettingIdFromCheckedContent.some((id) => id === item?.content?._id));
    // console.log(__gettingUsingContent)
    if (__gettingUsingContent.length > 0) {
      toast.success(t("views.redesignFile.contentUsingInPlaylist"));
    } else {
      dispatch(deleteMultipleContent(_checkedList));
    }
  };

  const onPreview_content = (previewContentObject) => {
    setPreviewContentUrl({
      url: previewContentObject.url,
      type: previewContentObject.contentType,
      embed: previewContentObject.embed,
      widget: previewContentObject.widget,
    });
    setOpenPreviewContentModel(true);
    handle.enter();
  };

  const onGoogleDrive = () => {
    setGoogleDriveFiles([]);
    openGoogelDrivePicker({
      appId: process.env.REACT_APP_GOOGLE_APP_ID,
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY,
      viewId: "DOCS_IMAGES_AND_VIDEOS",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === "loaded") {
          setBrowse(false);
        }
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        if (data.action === "picked") {
          setGoogleDriveFiles(data.docs.filter((f) => f.type == "photo" || f.type == "video"));
        }
      },
    });
  };

  useEffect(() => {
    if (!googleDriveAuth || !googleDriveFiles || !googleDriveFiles?.length) return;

    const userData = {
      user: user.isMember ? user?.user._id : user._id,
      subuser: user.isMember ? user?.user._id : user._id,
      role: user.isMember ? user?.role.split("-")[0] : user?.role,
      folderId: currentFolder,
    };

    console.log("Upload GoogleDrive", googleDriveFiles, googleDriveAuth);

    dispatch(
      addContentFromGoogleDrive({
        files: googleDriveFiles,
        auth: googleDriveAuth,
        ...userData,
      })
    );
  }, [googleDriveAuth, googleDriveFiles]);

  const onDropboxFiles = (files) => {
    let objFiles = [];
    files.forEach((f) => {
      objFiles.push({
        id: f.id,
        name: f.name,
        type: f.link.substring(f.link.lastIndexOf(".") + 1).split("?")[0],
        url: f.link.replace("?dl=0", "?dl=1"),
        embed: "embed",
        role: user?.role,
        user: user?._id,
        subuser: user?._id,
        folderId: currentFolder,
      });
    });
    //dispatch(getContentFromGoogleDrive(objFiles))
  };

  const onOneDrive = () => {
    const userData = {
      user: user.isMember ? user?.user._id : user._id,
      subuser: user.isMember ? user?.user._id : user._id,
      role: user.isMember ? user?.role.split("-")[0] : user?.role,
      folderId: currentFolder,
    };

    openOneDrivePicker({
      onFilePick: (file) => {
        dispatch(
          addContentFromOneDrive({
            ...file,
            ...userData,
          })
        );
      },
    });
    setBrowse(false);
  };

  const onSearchBarChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const [searchInput, setSearchInput] = useState("");

  const filteredFiles = files.filter((el) => {
    if (searchInput === "") {
      return el;
    } else {
      return el.name.toLowerCase().includes(searchInput);
    }
  });

  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  return (
    <>
      <Layout title={t("views.redesignFile.files")} user={user} playlistAddBtn={false} position={"relative"}>
        {isContentAdding && (
          <>
            <Box
              sx={{
                height: "100vh",
                width: "100vw",
                position: "absolute",
                top: 0,
                // left: 0,
                bottom: 0,
                right: 0,
                zIndex: 10000000000000,
                background: "rgba(0,0,0,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Circularbar height={"150px"} width={"150px"} />
            </Box>
          </>
        )}
        {isDeleting && (
          <>
            <Box
              sx={{
                height: "100vh",
                width: "100vw",
                position: "absolute",
                top: 0,
                // left: 0,
                bottom: 0,
                right: 0,
                zIndex: 10000000000000,
                background: "rgba(0,0,0,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Circularbar height={"150px"} width={"150px"} />
            </Box>
          </>
        )}

        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
          <Container maxWidth={settings.stretch ? false : "xl"}>
            <Grid container spacing={{ xs: 3, lg: 4 }}>
              {/* <Grid xs={12}>
                <Stack direction="row" justifyContent="space-between" spacing={4}>
                  <div>
                    <Typography variant="h4">File Manager</Typography>
                  </div>
                </Stack>
              </Grid> */}

              <Box sx={{ display: "flex", minWidth: "100%", flexDirection: { md: "row", xs: "column-reverse" } }}>
                {/* left column */}
                <Grid xs={12} md={9}>
                  <Stack spacing={{ xs: 3, lg: 4 }}>
                    <RedesignBodyHeader
                      user={user}
                      view={viewValue}
                      onViewChange={() => setViewValue(!viewValue)}
                      onAddPlaylist={onAddPlaylist}
                      onMove={onMove}
                      onCheckedContent={onCheckedContent}
                      onUncheckedContent={onUncheckedContent}
                      onGetCheckedContent={onGetCheckedContent}
                      isChecked={isChecked}
                      onSearchBarChange={onSearchBarChange}
                    />
                    <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap", margin: "20px 0 10px 0" }}>
                      {userFolders.map((folder, index) => {
                        return (
                          <>
                            <Folders key={index} folder={folder} onFolderDelete={onFolderDelete} onFolderEdit={onFolderEdit} />
                          </>
                        );
                      })}
                    </Box>
                    <RedesignBodyHeaderBottom
                      user={user}
                      onCheckedContent={onCheckedContent}
                      onUncheckedContent={onUncheckedContent}
                      onGetCheckedContent={onGetCheckedContent}
                      onMoveCheckedContent={onMoveCheckedContent}
                      isChecked={isChecked}
                      isCheckedAll={isCheckedAll}
                    />
                    <Box sx={{ marginTop: "0px !important" }}>
                      <Component
                        content={filteredFiles}
                        onAddPlaylist={onAddPlaylist}
                        onPushScreen={(contentObject) => console.log(contentObject)}
                        onDelete={onDelete}
                        onMove={onMove}
                        onPreview={onPreview_content}
                        view={viewValue}
                        onCheckedSingleContent={onCheckedSingleContent}
                        onUncheckedSingleContent={onUncheckedSingleContent}
                        handleSelectAll={isCheckedAll}
                        onUncheckedContent={onUncheckedContent}
                      />
                    </Box>
                  </Stack>
                </Grid>

                {/* right column */}
                <Grid xs={12} md={3}>
                  <Card sx={{ p: 2 }}>
                    {/* <CardHeader sx={{ padding: "12px 12px 24px 12px" }} title="Upload files" /> */}
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px" }}>
                      <Button
                        size={"large"}
                        variant={"contained"}
                        onClick={() => setBrowse(true)}
                        startIcon={<FileUploadIcon />}
                        disable={user.isMember ? (user?.previlliages?.content?.canCreate ? false : true) : false}>
                        {t("views.redesignFile.uploadFiles")}
                      </Button>
                      <Button
                        size={"large"}
                        variant={"contained"}
                        onClick={() => setOpenWidget(true)}
                        disable={user.isMember ? (user?.previlliages?.content?.canCreate ? false : true) : false}>
                        {t("views.redesignFile.apps")}
                      </Button>
                      <Button size={"large"} variant={"contained"} disabled>
                        {t("views.redesignFile.templates")}
                      </Button>
                    </Box>
                    <Box>
                      <CardHeader sx={{ padding: "24px 12px 12px 12px" }} title={t("views.redesignFile.type")} subheader={t("views.redesignFile.selectTypeOfFiles")} />
                      <Box sx={{ display: "flex", flexDirection: { md: "column", xs: "row" }, flexWrap: "wrap", alignItems: "flex-start", justifyContent: "flex-start" }}>
                        <Button onClick={() => setKey("all")} startIcon={<FileCopyIcon />}>
                          {t("views.redesignFile.all")}
                        </Button>
                        <Button onClick={() => setKey("images")} startIcon={<ImageIcon />}>
                          {t("views.redesignFile.images")}
                        </Button>
                        <Button onClick={() => setKey("videos")} startIcon={<SlowMotionVideoIcon />}>
                          {t("views.redesignFile.videos")}
                        </Button>
                        <Button onClick={() => setKey("widgets")} startIcon={<AppRegistrationIcon />}>
                          {t("views.redesignFile.apps")}
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              </Box>
            </Grid>
          </Container>
        </Box>
      </Layout>

      {/* modals */}

      <CreateWidgetModel isOpen={openWidget} onTakeData={(data) => setData((prev) => [...prev, data])} onClose={() => setOpenWidget(false)} />

      <Dialog open={openPreviewContentModel} onClose={() => setOpenPreviewContentModel(false)} maxWidth={"lg"}>
        <DialogTitle sx={{ textAlign: "center" }}>{t("views.redesignFile.filePreview")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: isMobile ? "70vw" : "50vw",
              height: "50vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0",
                gap: "10px 10px",
              }}>
              <Previewer previewContentUrl={previewContentUrl} />
              <Button
                variant={"outlined"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}
                onClick={() => setOpenPreviewContentModel(false)}>
                {t("views.redesignFile.close")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* content preview model end */}

      <ConfirmationModel
        openCreateModel={openCreateModel}
        title={t("views.redesignFile.confirmDelete")}
        onClose={() => setOpenCreateModel(false)}
        onConfirm={onConfirm}
        Icon={DeleteIcon}
      />
      <ConfirmationModel
        openCreateModel={openDeleteModel}
        title={t("views.redesignFile.confirmDelete")}
        onClose={() => setOpenDeleteModel(false)}
        onConfirm={onConfirmDeleteFile}
        Icon={DeleteIcon}
      />
      <ConfirmationModel
        openCreateModel={openBulkDeleteModel}
        title={t("views.redesignFile.confirmDelete")}
        onClose={() => setOpenBulkDeleteModel(false)}
        onConfirm={onBulkDeleteFileConfirm}
        Icon={DeleteIcon}
      />

      <Dialog open={openMoveModel} onClose={() => setOpenMoveModel(false)} maxWidth={"lg"}>
        <DialogTitle sx={{ textAlign: "center" }}>{t("views.redesignFile.selectFolder")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "40vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box style={{ width: "100px", height: "100px", margin: "0 auto" }}>
              <FolderIcon
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <Select value={folderVal} onChange={(e) => setFolderVal(e.target.value)} fullWidth>
                {[
                  {
                    _id: "root",
                    name: t("views.redesignFile.home"),
                    folderFor: "content",
                    path: [],
                    parent: "",
                  },
                  ...contentFolders,
                ].map((folders) => (
                  <MenuItem value={folders._id}>{folders.name}</MenuItem>
                ))}
              </Select>
            </Box>

            <DialogActions>
              <Button onClick={() => setOpenMoveModel(false)}>{t("views.redesignFile.close")}</Button>
              <Button onClick={moveOnFolder} variant={"contained"}>
                {t("views.redesignFile.save")}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openBulkMoveModel} onClose={() => setOpenBulkMoveModel(false)} maxWidth={"lg"}>
        <DialogTitle sx={{ textAlign: "center" }}>{t("views.redesignFile.selectFolder")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "40vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box style={{ width: "100px", height: "100px", margin: "0 auto" }}>
              <FolderIcon
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <Select value={folderVal} onChange={(e) => setFolderVal(e.target.value)} fullWidth>
                {[
                  {
                    _id: "root",
                    name: t("views.redesignFile.home"),
                    folderFor: "content",
                    path: [],
                    parent: "",
                  },
                  ...contentFolders,
                ].map((folders) => (
                  <MenuItem value={folders._id}>{folders.name}</MenuItem>
                ))}
              </Select>
            </Box>

            <DialogActions>
              <Button onClick={() => setOpenBulkMoveModel(false)}>{t("views.redesignFile.close")}</Button>
              <Button onClick={moveOnFolderBulk} variant={"contained"}>
                {t("views.redesignFile.save")}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openPlaylist} onClose={() => setOpenPlaylist(false)} maxWidth={"lg"}>
        <DialogTitle sx={{ textAlign: "center" }}>{t("views.redesignFile.selectPlaylist")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "40vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box style={{ width: "100px", height: "100px", margin: "0 auto" }}>
              <FaList
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <Select value={playlistId} onChange={(e) => setPlaylistId(e.target.value)} fullWidth>
                {playlists.map((playlist) => (
                  <MenuItem value={playlist._id}>{playlist.playlistName}</MenuItem>
                ))}
              </Select>
            </Box>

            <DialogActions>
              <Button onClick={() => setOpenPlaylist(false)}>{t("views.redesignFile.close")}</Button>
              <Button onClick={addInPlaylist} variant={"contained"}>
                {t("views.redesignFile.save")}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>

      <AddContentModel
        browse={browse}
        onClose={(e) => setBrowse(e)}
        fileUpload={fileUpload}
        fileUploadDragDrop={fileUploadDragDrop}
        onGoogleDrive={onGoogleDrive}
        onDropboxFiles={onDropboxFiles}
        onOneDrive={onOneDrive}
        reOpen={() => setBrowse(true)}
      />

      <Dialog open={openFolderEditModel} onClose={() => setOpenFolderEditModel(false)}>
        <FormControl component="form" onSubmit={(e) => e.preventDefault()}>
          <DialogTitle style={{ textAlign: "center" }}>{t("views.redesignFile.editFolder")}</DialogTitle>
          <DialogContent style={{ borderRadius: "50px" }}>
            <Box
              sx={{
                display: "flex",
                gap: "2px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Box style={{ width: "300px", height: "50px", margin: "20px auto" }}>
                <TextField required type={"text"} value={folderName} onChange={(e) => setFolderName(e.target.value)} fullWidth />
              </Box>
              <DialogActions>
                <Button onClick={() => setOpenFolderEditModel(false)}>{t("views.redesignFile.cancel")}</Button>
                <Button onClick={onConfirmFolderEdit} variant={"contained"} type="submit">
                  {t("views.redesignFile.save")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </FormControl>
      </Dialog>
    </>
  );
}

export default RedesignFile;
