import {
  ADD_CONTENT,
  GET_CONTENT_FAILED,
  GET_CONTENT_SUCCESS,
  CONTENT_DELETE_SUCCESS,
  CONTENT_DELETE_REQUEST,
  CONTENT_FLAG_OFF,
  CONTENT_MOVE_TO_FOLDER,
  CONTENT_DESC_ORDER_BY_CREATEDAT,
  CONTENT_ASC_ORDER_BY_CREATEDAT,
  CONTENT_DESC_ORDER_BY_NAME,
  CONTENT_ASC_ORDER_BY_NAME,
  CHECKED_CONTENT,
  UNCHECKED_CONTENT,
  CHECKED_SINGLE_CONTENT,
  UNCHECKED_SINGLE_CONTENT,
  DELETE_BULK_CONTENT,
  RESTORE_FILES_REQUEST,
  RESTORE_FILES_SUCCESS,
  ADD_CONTENT_REQUEST,
  ADD_CONTENT_FAILED,
  GET_CONTENT_FROM_GOOGLE_DRIVE,
  PICKING_DATA_FROM_GOOGLE,
  UPLOAD_FAILED,
  CONTENT_MOVE_SUCCESS,
  CONTENT_MOVE_REQUEST,
  CONTENT_MOVE_FAILED,
} from "../constant";
import { api, baseApi } from "../../api/endpoints";
export const flagContentOff = () => {
  return async (dispatch) => {
    dispatch({
      type: CONTENT_FLAG_OFF,
      payload: "",
    });
  };
};
export const addContent = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_CONTENT_REQUEST,
      payload: "",
    });
    // console.log(obj);
    try {
      const { data, status } = await api.post(`/content/contentupload`, obj);
      if (status === 201) {
        if (data.content.length > 0) {
          dispatch({ type: ADD_CONTENT, payload: data.content });
          return data.content;
        } else {
          dispatch({
            type: ADD_CONTENT_FAILED,
            payload: "",
          });
        }
      }
    } catch (error) {
      dispatch({
        type: UPLOAD_FAILED,
        payload: error.response.data.toString(),
      });
    }
  };
};
export const addWidget = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_CONTENT_REQUEST,
      payload: "",
    });
    const { data, status } = await api.post(`/content/addwidgets`, obj);
    console.log({ data, status });
    if (status === 201) {
      // if(data.content.length > 0){
      dispatch({ type: ADD_CONTENT, payload: [{ ...data.content }] });
      // }
      // else{
      //   dispatch({
      //     type:ADD_CONTENT_FAILED,
      //     payload:''
      //   })
      // }
    }
  };
};
export const getContentById = (id) => {
  return async (dispatch) => {
    const content = await api.get(`/content/getcontents/${id}`);
    if (content.status === 200) {
      if (content.data.content.length > -1) {
        dispatch({
          type: GET_CONTENT_SUCCESS,
          payload: content.data.content,
        });
      } else {
        dispatch({
          type: GET_CONTENT_FAILED,
          payload: "",
        });
      }
    }
  };
};
export const getContent = () => {
  return async (dispatch) => {
    //   dispatch({
    //     type: GET_CONTENT_REQUEST,
    //     payload: "",
    //   });
    const content = await api.get(`/content/getcontents`);
    if (content.status === 200) {
      if (content.data.content.length > -1) {
        dispatch({
          type: GET_CONTENT_SUCCESS,
          payload: content.data.content,
        });
      } else {
        dispatch({
          type: GET_CONTENT_FAILED,
          payload: "",
        });
      }
    }
  };
};
export const deleteContent = (id) => {
  return async (dispatch) => {
    dispatch({
      type: CONTENT_DELETE_REQUEST,
      payload: "",
    });
    const content = await api.patch(`/content/delete/${id}`);
    if (content.status === 201) {
      dispatch({
        type: CONTENT_DELETE_SUCCESS,
        payload: id,
      });
    }
  };
};
export const contentMoveToFolder = (obj) => {
  return async (dispatch) => {
    const content = await api.patch(`/content/contentmovetofolder/${obj.id}`, obj);
    dispatch({
      type: CONTENT_MOVE_TO_FOLDER,
      payload: content.data.content,
    });
  };
};

export const contentDescByCreatedAt = () => {
  return async (dispatch) => {
    dispatch({
      type: CONTENT_DESC_ORDER_BY_CREATEDAT,
      payload: "",
    });
  };
};
export const contentAscByCreatedAt = () => {
  return async (dispatch) => {
    dispatch({
      type: CONTENT_ASC_ORDER_BY_CREATEDAT,
      payload: "",
    });
  };
};
export const contentDescByName = () => {
  return async (dispatch) => {
    dispatch({
      type: CONTENT_DESC_ORDER_BY_NAME,
      payload: "",
    });
  };
};
export const contentAscByName = () => {
  return async (dispatch) => {
    dispatch({
      type: CONTENT_ASC_ORDER_BY_NAME,
      payload: "",
    });
  };
};
export const checkedContent = (currentFolder) => {
  return async (dispatch) => {
    dispatch({
      type: CHECKED_CONTENT,
      payload: currentFolder,
    });
  };
};
export const unCheckedContent = (currentFolder) => {
  return async (dispatch) => {
    dispatch({
      type: UNCHECKED_CONTENT,
      payload: currentFolder,
    });
  };
};
export const checkedSingleContent = (id) => {
  return async (dispatch) => {
    dispatch({
      type: CHECKED_SINGLE_CONTENT,
      payload: id,
    });
  };
};
export const unCheckedSignleContent = (id) => {
  return async (dispatch) => {
    dispatch({
      type: UNCHECKED_SINGLE_CONTENT,
      payload: id,
    });
  };
};
export const deleteMultipleContent = (contentArray) => {
  return async (dispatch) => {
    console.log("from api calling=>", contentArray);
    dispatch({
      type: CONTENT_DELETE_REQUEST,
      payload: "",
    });
    const deleted = await api.patch("/content/multipledelete", { contentArray });
    if (deleted.status === 201) {
      dispatch({
        type: DELETE_BULK_CONTENT,
        payload: contentArray,
      });
    }
  };
};
export const restoreFile = (id) => {
  return async (dispatch) => {
    dispatch({
      type: RESTORE_FILES_REQUEST,
      payload: "",
    });
    const content = await api.patch(`/content/filerestore/${id}`);
    if (content.status === 201) {
      dispatch({
        type: RESTORE_FILES_SUCCESS,
        payload: id,
      });
    }
  };
};
export const pickingDataFromGoogle = () => {
  return async (dispatch) => {
    dispatch({
      type: PICKING_DATA_FROM_GOOGLE,
      payload: "",
    });
  };
};
export const getContentFromGoogleDrive = (obj) => {
  return async (dispatch) => {
    const { status, data } = await api.post("/content/addcontentfromsocialmedia", obj);
    dispatch({
      type: GET_CONTENT_FROM_GOOGLE_DRIVE,
      payload: data.content,
    });
  };
};
export const addContentFromGoogleDrive = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_CONTENT_REQUEST,
      payload: "",
    });

    const { status, data } = await api.post("/content/addcontentfromgoogledrive", obj);

    if (status === 201) {
      if (data.content.length > 0) {
        dispatch({ type: ADD_CONTENT, payload: data.content });
      } else {
        dispatch({
          type: ADD_CONTENT_FAILED,
          payload: "",
        });
      }
    }
  };
};
export const addContentFromOneDrive = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_CONTENT_REQUEST,
      payload: "",
    });

    const { status, data } = await api.post("/content/addcontentfromonedrive", obj);

    if (status === 201) {
      if (data.content.length > 0) {
        dispatch({ type: ADD_CONTENT, payload: data.content });
      } else {
        dispatch({
          type: ADD_CONTENT_FAILED,
          payload: "",
        });
      }
    }
  };
};
